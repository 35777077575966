// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-case-studies-data-unions-jsx": () => import("./../../../src/pages/case-studies/data-unions.jsx" /* webpackChunkName: "component---src-pages-case-studies-data-unions-jsx" */),
  "component---src-pages-case-studies-machine-witness-jsx": () => import("./../../../src/pages/case-studies/machine-witness.jsx" /* webpackChunkName: "component---src-pages-case-studies-machine-witness-jsx" */),
  "component---src-pages-case-studies-pave-motors-jsx": () => import("./../../../src/pages/case-studies/pave-motors.jsx" /* webpackChunkName: "component---src-pages-case-studies-pave-motors-jsx" */),
  "component---src-pages-case-studies-swash-jsx": () => import("./../../../src/pages/case-studies/swash.jsx" /* webpackChunkName: "component---src-pages-case-studies-swash-jsx" */),
  "component---src-pages-case-studies-tracey-jsx": () => import("./../../../src/pages/case-studies/tracey.jsx" /* webpackChunkName: "component---src-pages-case-studies-tracey-jsx" */),
  "component---src-pages-data-token-jsx": () => import("./../../../src/pages/data-token.jsx" /* webpackChunkName: "component---src-pages-data-token-jsx" */),
  "component---src-pages-design-jsx": () => import("./../../../src/pages/design.jsx" /* webpackChunkName: "component---src-pages-design-jsx" */),
  "component---src-pages-developers-jsx": () => import("./../../../src/pages/developers.jsx" /* webpackChunkName: "component---src-pages-developers-jsx" */),
  "component---src-pages-discover-data-token-jsx": () => import("./../../../src/pages/discover/data-token.jsx" /* webpackChunkName: "component---src-pages-discover-data-token-jsx" */),
  "component---src-pages-discover-marketplace-jsx": () => import("./../../../src/pages/discover/marketplace.jsx" /* webpackChunkName: "component---src-pages-discover-marketplace-jsx" */),
  "component---src-pages-discover-network-jsx": () => import("./../../../src/pages/discover/network.jsx" /* webpackChunkName: "component---src-pages-discover-network-jsx" */),
  "component---src-pages-ecosystem-jsx": () => import("./../../../src/pages/ecosystem.jsx" /* webpackChunkName: "component---src-pages-ecosystem-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-fund-jsx": () => import("./../../../src/pages/fund.jsx" /* webpackChunkName: "component---src-pages-fund-jsx" */),
  "component---src-pages-grants-jsx": () => import("./../../../src/pages/grants.jsx" /* webpackChunkName: "component---src-pages-grants-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-network-jsx": () => import("./../../../src/pages/network.jsx" /* webpackChunkName: "component---src-pages-network-jsx" */),
  "component---src-pages-papers-jsx": () => import("./../../../src/pages/papers.jsx" /* webpackChunkName: "component---src-pages-papers-jsx" */),
  "component---src-pages-roadmap-jsx": () => import("./../../../src/pages/roadmap.jsx" /* webpackChunkName: "component---src-pages-roadmap-jsx" */),
  "component---src-pages-stake-and-earn-jsx": () => import("./../../../src/pages/stake-and-earn.jsx" /* webpackChunkName: "component---src-pages-stake-and-earn-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-token-migration-jsx": () => import("./../../../src/pages/token-migration.jsx" /* webpackChunkName: "component---src-pages-token-migration-jsx" */),
  "component---src-pages-whitepapers-jsx": () => import("./../../../src/pages/whitepapers.jsx" /* webpackChunkName: "component---src-pages-whitepapers-jsx" */)
}

